.footnotes{
    &:before {
        content: "Footnotes";
        font-weight: bold;
        padding-bottom: .5em;
        padding-top: .5em;
        border-top: 1pt dotted #d1d1d1;
        display: block;
    }

    ol {
        font-size: .8em;
    }
}

@media print {
    .post-content {
        width: 100% !important;
    }
    .post-sidebar {
        width: 0px;
        display: none;
    }
    #disqus_thread {
        display: none !important;
    }
    // .igtranslator-main-div{
    //     display: none !important;
    // }
}

// aside#post-toc {
//     position: sticky;
//     top: 2em;
//     z-index: 2;
// }

#post-toc {
    li.lvl2 {
        margin-left: 1.5em;
        list-style: disc outside;
    }

    &.stick {
        margin-top: 0 !important;
        position: fixed;
        top: 20px;
        z-index: 2;
    }
}

.red    {color: #DB2828;}
.orange {color: #F2711C;}
.yellow {color: #FBBD08;}
.olive  {color: #B5CC18;}
.green  {color: #009900;}
.teal   {color: #00B5AD;}
.ocean  {color: #2185D0;}
.violet {color: #6435C9;}
.purple {color: #A333C8;}
.brown  {color: #A5673F;}
.grey   {color: #767676;}
.black  {color: #1B1C1D;}

p:last-child {
    margin-bottom: 0;
}
p.spaced {
    margin-bottom: 2.5rem;
}

.spacer {
    padding: 2rem;
    display: block;
    clear: both;
}

.fa, .fa-solid, .fa-regular, .fa-brands {
    vertical-align: -.1rem;
    &.fa-star { vertical-align: 0rem; }
    &.fa-big {
        font-size: 2em;
        vertical-align: -.2em;
        padding-right: .3em;
    }
}

.button.secondary,
button.secondary,
input[type="submit"].secondary,
input[type="reset"].secondary,
input[type="button"].secondary {
  background-color: #D9D9D9;
  border-color: #999;
  &:hover {
      border-color: #656565;
  }
}

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    margin-right: .5em;
    &:last-child {
        margin-right: 0;
    }
}

.img a:hover {
    border: none;
}

.paper-stack {
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  padding: 1.5em;
  position: relative;
}
.paper-stack:before, .paper-stack:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.paper-stack:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}
.paper-stack:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}


#about {
    position: relative;
    @media (min-width: 966px) {
        #portrait {
            img {
                display: none;
            }
            #portrait-img {
                background-image: url(/images/portrait-bg.jpg);
                background-size: 100%;
                background-position-y: 0;
                background-repeat: no-repeat;
                min-height: 50em;
            }
            .button.primary {
                display: none;
            }
        }
        #bio {
            position: absolute;
            top: 1em;
            left: 1em;
            width: 57%;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 1.5em;
            border: 1px solid #ccc;
            // margin-top: 1em;
            // margin-left: 1em;
            text-align: justify;
            .button.primary {
                display: inline-block;
            }
        }
    }
    #portrait img {
        max-height: 200px;
    }
    @media (min-width: 720px) {
        #portrait img {
            max-height: 300px;
        }
    }
    @media (max-width: 965px) {
        #portrait {
            float: right;
            text-align: center;
            margin: 1rem 0 0 1em;

            img {
                display: inline;
                margin-bottom: .5em;
            }

            .button.primary {
                display: inline-block;
            }
        }
        #bio {
            position: static;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 0em;
            border: none;
            .button.primary {
                display: none;
            }
        }
    }
}

.full-width {
    width: 100%;
}

.ui.button {
    background: #eee;
    padding: 0px .6rem;
    line-height: 2.6rem;
    height: 2.6rem;
}
ul.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  font-size: .8em;
  line-height: 3em;
}

ul.tags li {
  float: left;
}

.tag {
  background: #eee;
  border-radius: 15px 2px 2px 15px;
  color: #999;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  padding: 0 5px 0 14px;
  position: relative;
  margin: 0 2px 0 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  border-right: 3px solid #ddd;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 4px;
  position: absolute;
  width: 6px;
  top: 8px;
}

a.tag.active, a.tag:hover {
  background-color: crimson;
  border-right-color: #9D0001;
  border-bottom: none;
  color: white;
}

.main {
    h1, h2, h3, h4, h5, h6 {
      font-family: Kreon, Georgia, sans-serif;
      font-weight: 400;
      letter-spacing: normal;
    }
}
.content-heading {
  font-family: Kreon, Georgia, sans-serif;
  font-weight: 400;
  letter-spacing: normal;
}

.post-list {
    list-style: none;

    li {
        margin-bottom: 1.5em;
    }

    .meta {
        time, .time {
            font-size: 1.2rem;
        }
        .venue {
            font-size: 1.3rem;
            font-weight: bold;
        }

        .comments {
            color: #6C6C6C;
            position: relative;
            display: inline-block;
            float: right;
            background: #fbfbfb;
            border-radius: 1em;
            height: 1.6em;
            min-width: 1.6em;
            text-align: center;
            line-height: 1.6em;
            border: 1px solid #e0e0e0;
            margin-right: .5em;
            padding: 0 .5em;
        }

        .decoration {
            float: right;
            line-height: 1.8em;

            .explain {
                display: none;
                padding-right: .5rem;
                font-size: 90%;
                vertical-align: .1rem;
                font-variant: small-caps;
            }

            &:hover {
                .explain {
                    display: inline-block;
                }
            }
        }

        .tags {
            color: #8a8a8a;
            position: relative;
            // display: inline-block;
            // float: right;
            // width: 1.6em;
            // height: 1.6em;
            // border: 1px solid #e0e0e0;
            // border-radius: 50%;
            // text-align: center;
            // background: #fbfbfb;
            padding-left: .5ex;
            vertical-align: -.4ex;
            // line-height: 1.8em;
        }
        .tag-list {
            display: none;
            // width: 120px;
            background-color: #eee;
            font-size: .8em;
            line-height: 1.5em;
            color: black;
            text-align: left;
            padding: 2px 1rem;
            border-radius: 3px;
            border-right: 3px solid #ddd;

            width: 20rem;
            left: 100%;
            top: 0;
            margin-left: 8px;

            /* Position the tooltip text - see below! */
            position: absolute;
            z-index: 1;
        }
        .tag-list::after {
            content: " ";
            position: absolute;
            right: 100%;
            top: 4px;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent #eee transparent transparent;
        }
        .tag-list.downwards {
            display: none;
            // width: 120px;
            background-color: #eee;
            font-size: .8em;
            line-height: 1.5em;
            color: black;
            text-align: center;
            padding: 2px 0;
            border-radius: 3px;
            border-bottom: 3px solid #ddd;

            width: 120px;
            top: 100%;
            left: 50%;
            margin-top: .8em;
            margin-left: -60px;

            /* Position the tooltip text - see examples below! */
            position: absolute;
            z-index: 1;
        }
        .tag-list.downwards::after {
            content: " ";
            position: absolute;
            bottom: 100%;  /* At the top of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #eee transparent;
        }
        .tags:hover .tag-list {
            display: block;
        }
        .tags:hover {
            color: black;
        }
    }

    h1 {
        font-size: 1.4em;
        margin-bottom: .2rem;
    }

    .excerpt {
        font-size: 1.4rem;
        color: grey;

    }


    .authors {
      margin: 3px 0 0 0;
      // border-top: 1px dotted #ccc;

      li {
        margin-bottom: 0;
      }

    }

}

#no-matches {
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.35);
    background-color: #fbfbfb;
    border-radius: 5px;
    padding: 3em;
}

.authors {
  /*display:  block;*/
  list-style: none;
  margin-bottom: .5em;
  padding: 0;


  li {
    display: inline-block;
    float: left;
  }

  li:after {
    content: ", ";
    letter-spacing: .5rem;
  }

  li:last-child:after {
    content: "";
  }

}

.authors:after {
  content: "";
  display: table;
  clear: both;
}

.meta {
    time, .time, .venue {
        line-height: 1.8;
        text-transform: uppercase;
        color: #717171;

        display: inline-block;
        margin-bottom: .5rem;
        border-bottom: 3px solid #ccc;
    }

}

// .post-sidebar aside.tags ul {
//     list-style: none;
//     font-size: 1.2rem;

//     li {
//         display: inline-block;
//         border: 1pt solid #ccc;
//         border-radius: 5px;
//         color: #353535;
//         background-color: #F6F6F6;
//         margin: .5rem;
//         padding: .2rem;
//     }
// }



.post-sidebar aside ul.tags li {
    margin-bottom: 0;
    line-height: 28px;
}

.post-sidebar aside, #disqus_thread {
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #D4D4D5,0 0 0 1px #D4D4D5;
    margin-bottom: 2em;
    display: block;
    border: none;
    background-color: #fbfbfb;
}
.post-sidebar aside {
    padding: 1em;
}
#disqus_thread {
    padding: 0 1em;
    margin-top: 2.5em;
}
.post-sidebar aside.share .buttons {
    text-align: center;
}
.post-sidebar aside ul.post-links {
    list-style: square;
    padding-left: .5em;
    color: #ccc;
    font-size: .9em;
}
.post-sidebar aside a {
    color: #3A3A3A;
}

.share .button {
    padding: 0px .6rem  0px .6rem;
    line-height: 2.6rem;
    height: 2.6rem;
    // width: 100%;
    i.fa, i.fa-solid, i.fa-regular, i.fa-brands {
        margin: auto;
    }
}
.post-sidebar aside.share a.button[href*=twitter] {
    color: white;
    background-color: #58A3D9;
    border-color: #0E4F77;
}
.post-sidebar aside.share a.button[href*=facebook] {
    color: white;
    background-color: #596686;
    border-color: #29487d;
}
.post-sidebar aside.share a.button[href*=plus] {
    color: white;
    background-color: #D15752;
    border-color: #8B201E;
}

.post-sidebar aside.share a.button.rss {
    color: white;
    background-color: #C57D41;
    border-color: #785D17;
}
.post-sidebar h1 {
    text-transform: uppercase;
    letter-spacing: .2rem;
    font-weight: 600;
    color: #575757;
    font-size: .9em;
}

.post-sidebar .navigation {
    margin-bottom: 1em;
}

.search-box {
    vertical-align: middle;
    white-space: nowrap;
    position: relative;
}
.search-box input[type="search"] {
    width: 100%;
    padding-left: 2.1em;
    margin-bottom: .5rem;
}
.search-box i {
    position: absolute;
    top: .7em;
    left: .7em;
    z-index: 1;
    color: #4f5b66;
}
.results-count {
    font-variant: small-caps;
    font-weight: bold;
}
.post-sidebar aside > *:last-child,
.post-sidebar aside *:last-child {
    margin-bottom: 0;
}
.post-sidebar aside li {
    margin-bottom: .1rem;
}

.search {
    ul.results {
        list-style: none;
        // padding-left: 1em;
        // margin-left: -1.5em;
        color: #ccc;
        font-size: .9em;
        margin-bottom: 0;
    }
    ul.results li:before {
        content: "•";
        display: inline-block;
        padding-right:.4em;
    }
    .search-result {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}


blockquote {
    padding: .5em 1.5em;
    margin: 1.5em auto;
    border-left: 5px solid #575757;
    color: #424242;
    background-color: #f2f2f2;
    // content: "";
    // display: table;
    clear: both;
    border-radius: 0 10px;
}
blockquote.positive {
    border: 1px solid #9cc177;
    border-left: 5px solid #34770e;
    color: #298201;
    background-color: #e8ffe6;
}
blockquote.note {
    border: 1px solid #77a5c1;
    border-left: 5px solid #0e4f77;
    color: #0e4f77;
    background-color: #e6f5ff;
    img:first-child {
        float: left;
        margin: .2em 1.5em .2em 0;
    }
}
blockquote.warning {
    border-left: 5px solid #af7814;
    color: #774f0e;
    background-color: #fff6e6;
}
blockquote.prize {
    border: 1pt solid #ead65f;
    border-left: 5px solid #daa520;
    color: #c28e0a;
    background-color: #fff9ea;
    a {
        color: inherit;
        font-weight: bold;
    }
}
blockquote.award {
    border: 1pt solid #ead65f;
    border-left: 5px solid #daa520;
    color: #c28e0a;
    background-color: #fff9ea;
    font-size: 130%;
    a {
        color: inherit;
        font-weight: bold;
    }
}
.golden {
    color: #daa520;
}

blockquote p {
    vertical-align: middle;
    display: inline-block;
    margin-bottom: auto;
}
blockquote button,
blockquote .button {
    float: right;
    margin: .5em 0 0 1.5em;
}

.tight {
    margin: 0 !important;
}

.float.right {
    float: right;
    text-align: center;
    margin: 0 0 0.5rem 1em;
}

.float.left {
    float: left;
    text-align: center;
    margin: 0 5em 0.5rem 0em;
}

a.plain:hover {
    border: none;
}

.contoured {
    border: 1px solid #ccc;
}

address {
    padding: 0rem 0rem 1rem 0rem;
}
address:before {
    content: "Address";
    display: block;
    font-weight: bold;
    /*margin-left: -3rem;*/
    /*margin-bottom: .5rem;*/
}

div.row + h1, div.row + h2, div.row + h3, div.row + h4, div.row + h5, div.row + h6 {
    margin-top: 3rem;
}

h6 {
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: .2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {
    margin-top: 1em;
}

.eml {
    cursor: pointer;
    &:hover {
        border-bottom: 1pt dotted;
    }
}

.eml.kl:after {
    content: "@cs.uni-kl.de";
}

.eml.gm:after {
    content: "@gmail.com";
}

.eml.kn:after {
    content: "@uni-konstanz.de";
}

header {
    margin-top: 35px;
    /*transition: .8s ease-in-out;*/
}

header h1 {
    margin-bottom: 0%;
    /*font-weight: bold;*/
}
header h1 a {
    color: inherit;
}
header h1 a:hover {
    border: none;
    color: black;
    text-shadow: 0pt 0pt 2pt #CFCFCF;
}

header h2 {
    margin-top: 0em;
    padding-top: 0em;
    padding-left: .12rem;
    font-size: 130%;
    color: gray;
    letter-spacing: 2pt;
}

button i.fa, .button i.fa,
button i.fa-solid, .button i.fa-solid,
button i.fa-regular, .button i.fa-regular,
button i.fa-brands, .button i.fa-brands {
    margin-right: .5rem;
    font-size: 1.6rem;
    // vertical-align: -0.05em;
    // font-size: 1.7rem;
    // vertical-align: -2px;
}

button.icon i.fa, .button.icon i.fa,
button.icon i.fa-solid, .button.icon i.fa-solid,
button.icon i.fa-regular, .button.icon i.fa-regular,
button.icon i.fa-brands, .button.icon i.fa-brands {
    margin: 0;
}
button.icon img, .button.icon img {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2px;
}
.spaced.row {
    margin-top: 2rem;
}

nav {
    // font-family: Kreon, Georgia, sans-serif;
    font-size: 120%;
    /*transition: .8s ease-in-out;*/
    position: sticky; top: 0px;
    z-index: 3;
}


nav .menu.head {
    text-transform: lowercase;
    min-height: 1.64em;
}

nav a {
    text-transform: lowercase;
    text-decoration: none;
    color: black;
    padding-bottom: .5rem;
}

nav .nav-item,
nav .nav-home {
    padding: .5rem 1.5rem;
}


nav a.active {
    font-weight: bold;
    border-bottom: 3pt solid;
}
nav a:hover {
    border-bottom: 3pt solid #0FA0CE;
}

p {
    font-feature-settings: "kern", "liga", "pnum", "tnum" off, "onum", "lnum" off, "dlig" off;
    /*
    text-align: justify;
    text-justify: auto;
    */
}
.justified {
    p {
        text-align: justify;
    }

    .image.float.right {
        margin-left: 2.5em;
    }

    .image.float.left {
        margin-right: 2.5em;
    }
}

p.prominent:first-letter {
    float:left;
    margin: .75rem .4rem 0 0;
    font-size: 5.7rem;
    line-height: 70%;
}

p.letterine:first-letter {
    float:left;
    margin: .75rem .4rem 0 0;
    font-size: 4.9rem;
    line-height: 60%;
}

p.prominent {
    font-size: 110%;
}

.clip {
    overflow: hidden;
}

footer {
    text-align: center;
    font-size: 80%;
    margin-top:3rem;
    margin-bottom:3rem;
    border-top: 1pt dotted #ccc;
    padding-top: 1em;
}

footer, footer a {
    color: grey;
}

.small.button {
    height: 25px;
    line-height: 25px;
    margin-bottom: 0;
    padding: 0 1rem;
    &:hover {
        border-color: #414141;
    }
}


.authors a, a.person {
    color: black;
}



.authors a, a.person {
    color: black;
}

.paper-card,
.post .paper-card {

    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #D4D4D5,0 0 0 1px #D4D4D5;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    /*width: 100%;*/
    display: block;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 0 1px #D4D4D5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .links {
        display: inline-block;
        float: right;
    }

    .links button,
    .links .button {
        height: 25px;
        line-height: 25px;
        margin-bottom: 0;
        padding: 0 1rem;
    }

    .links button:hover,
    .links .button:hover {
        border-color: #414141;
    }
    //  .links .primary {
    //   color: #FFF;
    //   background-color: #33C3F0;
    //   border-color: #33C3F0;
    //   /*background-color: #568FEA;*/
    //   /*border-color: #33C3F0;*/
    // }
    .links button.primary:hover,
    .links .button.primary:hover {
        border-color: #385671;
    }

    .venue {
        font-weight: bold;
        display: inline-block;
        line-height: 2.5em;
    }

    .venue + .year {
        margin-left: .5rem;
    }

    .record {
        float: left;
    }

    .authors {
      /*display:  block;*/
      list-style: none;
      margin-bottom: 0;
      margin-left: 0;
      font-size: 1.45rem;
    }

    .authors:after {
      content: "";
      display: table;
      clear: both;
    }

    .authors li {
      display: inline-block;
      float: left;
      margin-bottom: 0;
    }

    .authors li:after {
      content: ", ";
      letter-spacing: .5rem;
    }

    .authors li:last-child:after {
      content: "";
    }

    /* .authors li:nth-last-child(2):after {
      content: " and ";
    }*/

    .title {
        font-size: 1.8rem;
        line-height: 1.1;
        font-family: Kreon, Georgia, sans-serif;
        font-weight: 400;
    }
    .icon {
        float: left;
        display: inline-block;
    }
}
.post .paper-card,
article .paper-card
{
    margin-bottom: 2.5rem;
}

.paper-title {
    font-size: 1.3em;
    line-height: 1.1;
    font-family: Kreon, Georgia, sans-serif;
    font-weight: 400;
}

.news-list {
    list-style: none;
    margin-left: 0;
    padding-left: 9rem;

    li {
        content: "";
        display: table;
        clear: both;
        margin-bottom: .5em;
    }

    .news {

        .content {
            padding-left: 2rem;
        }

        .date {
            width: 9rem;
            margin-left: -9rem;
            float: left;
            text-align: right;
            border-bottom: 3px solid #DDD;
            margin-bottom: 0;
        }

        .fa, .fa-solid, .fa-regular, .fa-brands {
            margin-right: .8ex;
        }
    }

}

.big.news-list {
    padding-left: 11rem;
    li {
        margin-bottom: 1.5em;
    }
    .date {
        width: 11rem;
        margin-left: -11rem;
    }
}


.side-links a {
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
    font-size: 1.4rem;
}

.card {
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #D4D4D5,0 0 0 1px #D4D4D5;
    padding: 0;
    margin-bottom: 2em;
    /*width: 100%;*/
    display: block;
    /*cursor: pointer;*/
    border: none;
    overflow: hidden;
    background-color: #fbfbfb;
}

.card:hover {
    border: none;
    color: inherit;
    /*border-bottom: .2em solid #ccc;*/
    /*margin-bottom: 0;*/
    box-shadow: 0 0 0 1px #D4D4D5,0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}

.card .title {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    color:  #CB1009;
}
.card .title > * {
    margin-bottom: 0;
}

.card .buttons {
    text-align: center;
    padding: 0 1.5rem .5rem 1.5rem;
    margin: 0;
}
.card .buttons .button {
    /*margin-bottom: 0;*/
    min-width: 7rem;
    padding: 0;
    font-size: 1rem;
    width: 100%;
    background-color: white;
}
.card .buttons .button i.fa,
.card .buttons .button i.fa-solid,
.card .buttons .button i.fa-regular,
.card .buttons .button i.fa-brands {
    margin: 0;
    font-size: 1.4rem;
    // vertical-align: -1px;
}
i.tight {
    margin-right: -3px !important;
}
.card .one.buttons .button {
    width: 100%;
}
.card .two.buttons .button {
    width: 48%;
}
.card .three.buttons .button {
    width: 32%;
}

.card p {
    padding: 1.5rem;
    margin-bottom: 0;
}
/*.card p + p {
    padding-top: 0;
}*/

.card .demo img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    // min-height: 100%;
}
.card .demo {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    /*margin: .5rem -1.5rem .5rem -1.5rem;*/
    position: relative;
    overflow: hidden;
    height: 13em;
    background-color: white;
}
@media (min-width: 720px) {
    .card .demo {
        height: 10em;
    }
}

.card .demo .dimmer {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    padding-top: 50px;
    background: rgba(0,0,0,.5);
    color: white;
    cursor: pointer;
}
.card .demo:hover .dimmer {
   z-index: 2;
   display: block;
}


.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
    display: none;
}

.overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}


.post, article {

  header {
      margin: auto;
      margin-bottom: 1em;
  }

  .title {
    font-family: Kreon, Georgia, sans-serif;
    font-weight: 400;
    position: relative;
    font-size: 2.4rem;
    line-height: 1;
    letter-spacing: normal;
    margin-bottom: .4em;
    // letter-spacing: -.05rem;
    @media (min-width: 720px) {
      font-size: 3rem;
    }
  }

  time, .time {
    font-size: 1.4rem;
  }

  .meta .venue {
    font-size: 1.1em;
    font-weight: bold;
  }

  time, .time, .meta .venue {
    line-height: 1.8;
    text-transform: uppercase;
    color: #717171;

    display: inline-block;
    margin-bottom: .5rem;
    border-bottom: 3px solid #ccc;
  }

  .content {

    margin-bottom: 2.5rem;
    font-size: 1.6rem;

    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
      font-weight: 300;
      margin-bottom: 1.5rem;
      font-weight: 300; }
      h1 { font-size: 2.4rem; line-height: 1.35; letter-spacing: -.08rem; }
      h2 { font-size: 1.8rem; line-height: 1.5;  letter-spacing: -.05rem; }
    // h3 { font-size: 2.4rem; line-height: 1.35; letter-spacing: -.08rem; }
    h3 {
      text-transform: uppercase;
      font-size: 1.6rem;
      letter-spacing: .2rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    /* Larger than phablet */
    @media (min-width: 720px) {
      h1 { font-size: 3.0rem; }
      h2 { font-size: 2.4rem; }
    }

    p {
      text-align: justify;
      word-wrap: break-word; }

    ul {
      list-style: disc outside; }
    ul ul,
    ol ul {
      list-style: circle outside; }
    ol {
      list-style: decimal outside; }
    ol, ul {
      margin-left: 2em;
      margin-top: 0; }
    ul ul,
    ul ol,
    ol ol,
    ol ul {
      margin: 1.5rem 0 1.5rem 3rem;
      font-size: 90%; }
    li {
      margin-bottom: 1rem; }

    figure, .wide-image {
        text-align: center;
        margin-left: 0;
        margin-right: 0;

        figcaption, figcaption p {
            text-align: center;
        }

        img {
            max-width: 100%;
        }
        blockquote, .caption {
            float: right;
            display: block-inline;
            .title {
                font-family: Kreon;
                font-style: normal;
                font-variant: small-caps;
                text-transform: lowercase;
                font-size: 1.2rem;
            }
        }
        .credits {
            float: right;
            font-size: 60%;
            margin-top: -5px;
            font-style: normal;
            color: black;

            &:hover {
                border: 0;
                color: #1EAEDB;
            }
        }
    }

    audio {
        width: 100%;
        margin-bottom: 2.5rem;
    }

  }

}


// Alternative (hanging)
// .post .title time, .time {
//     margin-right: 2rem;
//     padding-top: .6rem;
//     min-width: 6em;
//     position: absolute;
//     right: 100%;
// }

.grant.summary {
    margin-bottom: 2.5rem;
    font-size: 1.8rem;

    p {
      text-align: justify;
      word-wrap: break-word; }
}

.fluid.embed {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.fluid.embed iframe,
.fluid.embed object,
.fluid.embed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.gm-style-iw * {
    display: block;
    width: 100%;
}
.gm-style-iw h4, .gm-style-iw p {
    margin: 0;
    padding: 0;
}
.gm-style-iw a {
    color: #4272db;
}

.ED {
    width: 20rem;
    right: 0px;
    position: absolute;
    top: -10.8em;
    /*opacity: 0;*/
    padding: 0px;
    margin: 0px;
    display: none;
    pointer-events: none;
    /*transition: .5s ease-in-out;*/
    /*transition-delay: 1s;*/
}

@media (min-width: 666px) {
  .ED { display: block; z-index: 2; }
  #homedrawing { display: block !important; }
}

#homedrawing {
    margin-top: 1rem;
    border-top: 2px solid #E1E1E1;
    /*transition: .5s ease-in-out;*/
    /*transition-delay: 1s;*/
    position: absolute;
    top: 300px;
    left: 0pt;
    /*right: 0pt;*/
    width: 100%;
    bottom:0pt;
    height: auto;
    overflow: visible;
    /*opacity: 0;*/
    display: none;
}

#homedrawing .showoff {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    /*bottom: 0px;*/
    overflow: hidden;
}

#homedrawing .showoff img {
    position: absolute;
    width: 130%;
}

#homedrawing .explain {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: .4rem .8rem;
  font-size: 80%;
  text-decoration: none;
}
#homedrawing .explain .ctrl {
    color: #777;
    font-size: 80%;
    cursor: pointer;
}

.homepage {
  // font-size: 60%;
  @media (min-width: 666px) {
    // background: #F2F2F2;
  }
}

.homepage .content.row {
  margin-top: 2px;
  padding: 0;
}
.homepage .container {
  pointer-events: none;
}

.homepage .content .column {
  background: white;
  pointer-events: all;
  /*background: rgba(255,255,255,.9)*/;
}
.homepage header.row {
  pointer-events: all;
}

.homepage #news {
    margin-top: 2em;
    margin-bottom: 2em;

    h6 {
      font-size: 2rem;
    }
    .side-links a {
      font-size: 1.7rem;
    }
}

.homepage #homedrawing,
.homepage .ED {
    opacity: 1;
}
.homepage .main {
    background-color: white;
}

.homepage nav .nav-item {
  // background-color: rgba(255,255,255,.8);
}


.animated header,
.animated nav {
    transition: .3s ease-in-out;
}

p.social {
    /*margin-top: -1rem;*/
    margin-top: 1rem;
}

.social.button {
  padding: 0px .6rem  0px .6rem;
  line-height: 2.6rem;
  height: 2.6rem;
  border-color: #474747;
  border-style: solid !important;
  border-width: 1px;
  background-color: white;
  margin-right: 0;
}

/*.social.button:hover {
    background-color: white !important;
    color: black !important;
    border-color: white !important;
}
*/
.social.long.button {
    min-width: 13rem;
    margin-right: 1rem;
}

.social.button i {
    font-size: 18px;
    vertical-align: -2px;
}

.social.button:not(:hover) img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

/*
.social.button[href*=mail]:hover {
  color: white;
  background-color: #575757;
  border-color: #222222;
}
*/
.social.button[href*=skype]:hover {
  color: white;
  background-color: #30ABDE;
  border-color: #0E4F77;
}

.social.button[href*=github]:hover {
  color: white;
  background-color: #575757;
  border-color: #222222;
}

.social.button[href*=tex]:hover {
  color: white;
  background-color: #771D1D;
  border-color: #3E0A0A;
}

.social.button[href*=twitter]:hover {
  color: white;
  background-color: #1B95E0;
  border-color: #0E4F77;
}

.social.button[href*=orcid]:hover {
  color: black;
  background-color: #f5f9e8;
  border-color: #1C2E08;
}

.social.button[href*=linkedin]:hover {
  color: white;
  background-color: #1f88be;
  border-color: #135679;
}

.social.button[href*=scholar]:hover {
  color: white;
  background-color: #417A44;
  border-color: #0C290E;
}

.social.button[href*=dblp]:hover {
  color: white;
  background-color: #A28941;
  border-color: #544A2F;
}

.social.button[href*=types]:hover {
  color: white;
  background-color: #595aff;
  border-color: #3A3BA7;
}

footer .social.button:not(:hover) {
  background-color: #fff;
  border-color: white;
}

.clear {
    clear: both;
}

aside.floating {
    float: right;
    width: 25%;
    margin-right: 1rem;
    margin-left: 1rem;
}

aside.info i.fa,
aside.info i.fa-solid,
aside.info i.fa-regular,
aside.info i.fa-brands {
    color: #4583CD;
    font-size: 1.2em;
}

aside.info {
    border-radius: .2em;
    border: .1em solid #a0bfd6;
    background: rgb(248, 251, 255);
    padding: 1rem;
    font-size: 90%;
}

.page-logo {
    float: right;
    text-align: center;
    margin: 0 0 0.5rem 1em;
    max-width: 30%;
    max-height: 190px;
}

.bibtex-ref code {
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.9);
    white-space: pre-wrap;
    overflow-x: auto;
}

/*------------------------------*/
/*
.ED-edge2 {
    position: absolute;
    top: 235.5px;
    left: 0pt;
    right: 0pt;

    height: 20px;
    border: 0;

    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #dbdbdb 100%);
    background: -webkit-linear-gradient(top,  #ffffff 0%,#dbdbdb 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#dbdbdb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dbdbdb',GradientType=0 );

}

.ED-edge {
    position: absolute;
    top: 255.5px;
    left: 0pt;
    right: 0pt;
    border-top: 2px solid #E1E1E1;
}
*/

.centered, .centered p {
    text-align: center !important;
}

table.centered {
    margin-left: auto;
    margin-right: auto;
}

.justified {
    text-align: justify !important;
}

img.framed {
    border: #cac8c8 1.5pt solid;
    box-sizing: border-box;
    padding: 3pt;
    border-radius: 3pt;
}

.logo.column {
    /*min-width: 150px;*/
    width: 100px;
    text-align: right;
    padding: 0;
    display: none;
}

.logo {
    /*width: 100px;*/
    position: absolute;
    right:0; top:0;
    width:100%;
    max-width: 130px;
    overflow: visible !important;
}

.side-by-side .column.image {
    text-align: center;
    vertical-align: center;
}


aside.toc {
    border-radius: .5rem;
    padding: 1rem 2rem 1rem 2rem;
    position: sticky;
    top: 2rem;
}
aside.toc li {
    margin-bottom: 0;
}
aside.toc:before {
    content: "Contents";
    display: block;
    font-size: 100%;
    font-weight: bold;
    margin-bottom: 1rem;
}

div.stargazer svg {
  font-family: "Roboto Mono", monospace;
}


/* Media Queries
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*
Note: The best way to structure the use of media queries is to create the queries
near the relevant code. For example, if you wanted to change the styles for buttons
on small devices, paste the mobile query code up in the buttons section and style it
there.
*/


/* Larger than mobile */
@media (min-width: 400px) {}

.when.collapsed.menu {
    display: none;
}

.justified .image.float.portrait {
    width: 40%;
    min-width:80px;

    @media (max-width: 549px) {
        width: 46%;
    }

    @media (max-width: 400px) {
        width: 100%;
        float: none;
        margin-left: 0pt;
        margin-right: 0pt;
    }
}

@media (max-width: 549px) {
    nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        font-size: 150%;
        padding-top: 0rem;
        padding-bottom: 0rem;
        text-align: center;
        background-color: #f7f7f7;
        /*margin-bottom: 1rem;*/
        border-bottom: 1pt solid #CACED5;
    }

    nav span {
        display: none;
        padding-top: .5rem;
        padding-bottom: 1rem;

    }
    nav .menu.head i.fa,
    nav .menu.head i.fa-solid,
    nav .menu.head i.fa-regular,
    nav .menu.head i.fa-brands {
        margin-top: 1.2rem;
        margin-right: 1.5rem;
    }
    nav .menu.head {
    /*nav:before {*/
        /*content: "Menu";*/
        color: black;
        display: block;
        padding: .5em 0 .5em 7.5%;
        font-weight: bold;
        font-size: .8em;
        text-align: right;
        text-align: left;
    }
    nav a {
        padding-bottom: 0;
    }
    nav.open span {
        display: block;
        background-color: #f7f7f7;
    }
    nav.open {
        padding-bottom: 1rem;
        border-bottom: 2pt solid #CACED5;
    }

    header {
        margin-top: 4.5em;
    }

    .when.collapsed.menu {
        display: block;
    }

}


@media print {
    nav {
        display: none;
    }
}

@media (min-width: 550px) {
    nav span:first-child,
    nav .nav-home + span,
    nav .menu.head + span {
        padding-left: 0;
    }
    nav .nav-home {
        display: none;
    }
    nav span:last-child {
        padding-right: 0;
    }
    .homepage nav {
        margin-bottom: 3px;
    }
    nav {
        margin-bottom: 3rem;
    }

    nav .menu.head {
        display: none;
    }

    .homepage p.social {
        width: 135%;
    }
    .homepage .content.row .column {
      padding: 5rem 1rem 0 1rem;
    }

    #homedrawing {
        margin-top: 0px;
    }

    footer .column:first-child {
        text-align: left;
        margin-left: 0;
    }
    footer .column {
        text-align: center;
    }
    footer .column:last-child {
        text-align: right;
    }

    footer .column {
        width: 30.6666666667%; margin-left: 4%;
    }
}

/*@media (min-width: 630px) and (max-width: 720px) {
    .side-by-side .left.side .column:first-child {
        width:75%;
    }
    .side-by-side .right.side .column:last-child {
        width:15%;
        min-width: 133px;
    }
}*/

@media (max-width: 719px) {

    .fluid.map {
        display: none;
        /*position: relative;*/
        /*padding-bottom: 56.25%;*/
        /*padding-top: 30px;*/
        /*height: 0;*/
        /*overflow: hidden;*/
    }

    .inessential {
        display: none;
    }

    aside.toc {
        display: none;
    }

}

/* Larger than phablet (also point when grid becomes active) */
/*@media (min-width: 550px) {*/
@media (min-width: 720px) {

    p.two-thirds {
        width: 65.3333333333%;
    }

    .on-small {
      display: none;
    }

    nav {
        width: 135%;
    }

    .side-by-side .right.side {
        clear:left;
        float:left;
        width:100%;
    }

    .side-by-side .left.side {
        float:left;
        width:100%;
        position:relative;
        right:50%;
    }
/*    .side-by-side .left.side .column:first-child {
        float:left;
        position:relative;
        left:50%;
        overflow:hidden;
    }
    .side-by-side .right.side .column:last-child {
        float:left;
        position:relative;
        left:50%;
        overflow:hidden;
    }*/
    .side-by-side .side .column {
        float:left;
        position:relative;
        left:50%;
        overflow:hidden;
    }


    .side-by-side {
        padding: 0;
        width:100%;
        overflow:hidden;
        border: 2px solid #E0E6ED;
    }

    .side-by-side .column {
        width: 50%;
        margin: 0;
        float:left;
    }
    .side-by-side .remaining.one-half.column {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 100%;
        height: 100%;
        width: 50%;
    }
    img.fitting {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .side-by-side .column.text {
        padding: 2em;
    }

    .dark {
        background-color: #E0E6ED;
    }
    .light {
        background-color: white;
    }
    .darker {
        background-color: #BAC6D5;
    }

    .fluid.map {
        position: absolute;
        top:0;
        left:0;
        height: 100% !important;
        width: 100% !important;
    }


/*}

@media (min-width: 720px) {*/

    .logo.column {
        width: 22%;
        display: block;
    }

    .name.column{
        width: 74.0%;
    }

}
@media (min-width: 665px) {

    .logo.column {
        display: block;
    }

}

/* Larger than tablet */
@media (min-width: 780px) {
    .ED {
        width: 35.5rem;
        top: -28.7rem;
        /*width: 373px;*/
        /*top: -300px;*/
    }
    .homepage header {
        margin-top: 9em;
    }

    .homepage nav {
        margin-bottom: 1rem;
        margin-top: 2em;
    }

    .homepage nav a {
        padding-bottom: 1.1rem;
    }
}

/* Larger than desktop */
@media (min-width: 1000px) {
    .container {
      width: 850px; }
    .ED {
        right: 7%;
    }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {}
